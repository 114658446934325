.DepartmentInfoCard {
    width: 100%;
    &-Wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    &-Logo{
        width: fit-content;
        display: flex;
        flex-direction: column;
        margin: auto 2rem;
        img{
            max-width: 100%;
        }
    }
    &-infotable{
        border: solid 5px white;
        border-radius: 2rem;
        width: fit-content;
        margin: .5rem;
        display: flex;
        flex-direction: column;
        table {
            border-collapse: collapse; 
            margin: 1rem;
            tr {
    
                font-size: 1.5rem;
                td {
                    font-family: NexaLight;
                    vertical-align: top;
                    text-align: left;
                    padding: .3rem 1rem;
                    div{
                        font-family: NexaLight;
                        padding-bottom: .25rem;
                    }
                }
    
                th {
                    font-family: NexaBold;
                    vertical-align: top;
                    text-align: right;
                    padding: .25rem 1rem;
                    border-right: solid white 3px;
                }
            }
        }
    }
    
}


@media (max-width: $media-breakpoint) {
    .DepartmentInfoCard-Logo{
        display: none;
    }
}

@media (max-width: 700px) {
    .DepartmentInfoCard{
        &-infotable{
            border: solid 3px white;
            margin: .25rem;
            table {
                margin: .75rem;
                tr {
                    font-size: 1rem;
                    td {
                        padding: .2rem .5rem;
                        div{
                            padding-bottom: .2rem;
                        }
                    }
        
                    th {
                        padding: .2rem .5rem;
                        border-right: solid white 2px;
                    }
                }
            }
        }
    }

}

@media (max-width: 420px) {
    .DepartmentInfoCard{
        &-infotable{
            border: solid 3px white;
            margin: .2rem;
            table {
                margin: .5rem;
                tr {
                    font-size: .825rem;
                    td {
                        padding: .25rem;
                        div{
                            padding-bottom: .2rem;
                        }
                    }
        
                    th {
                        padding: .125;
                        border-right: solid white 2px;
                    }
                }
            }
        }
    }

}