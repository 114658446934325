.impressum {
    padding: 5rem 0;
    color: white;
    background-color: $color-prime-dark;
  
    &-title {
      text-align: center;
      font-size: 3rem;
      padding-top: 3rem;
    }
  
    &-content {
      padding-left: 10vw;
      padding-right: 10vw;
  
      &-title {
        padding-top: 2rem;
        font-size: 2rem;
      }
  
      &-text {
        margin-top: 0.75rem;
        font-size: 1.4rem;
        font-weight: normal;
  
        &-mail {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  
  @media (max-width: 700px) {
    .impressum-content {
      padding-left: 5vw;
      padding-right: 5vw;
    }
  
    .impressum-title {
      font-size: 2.5rem;
    }
  
    .impressum-content-title {
      font-size: 1.5rem;
    }
  
    .impressum-content-text {
      font-size: 1.2rem;
    }
  }
  