.header{
    height: 4rem;
    width: 100vw;
    background-color: $color-prime-light;
    display: flex;
    justify-content: flex-start;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    z-index: 60;

    &-desktop{
      display: flex;
      justify-content: space-between;
      width: 100%;
      &-logo{
        margin: .5rem .75rem;
        svg{
          width: 6rem;
        }  
      }

      &-links{
        display: flex;
        justify-content: space-evenly;
        &-btn{
          min-width: 5rem;
          margin: 0 1rem;

          border: none;
          background-color: transparent;
          
          a{
            text-decoration: none;
            font-size: 2rem;
          font-family: uniSans;
          color: white;
          padding: 1rem;
          }

          &-special{
            background-color: white;
            border-radius: 1.5rem;
            margin: .35rem;
            a{
              color: $color-prime-light;
            }
          }
        }
      }

      &-socials{
        margin-right: 1rem;
        &-btn{
          //min-width: 5rem;
          min-width: 2.5rem;
          margin:  .75rem;
          border: none;
          background-color: transparent;
          
          

          a{
            text-decoration: none;
            font-size: 1rem;
            color: white;

            svg{
              fill: white
            }
          }

        }
      }
    }
    &-mobile{
      display: none;
      width: 100%;
      justify-content: space-between;

      &-burgerMenu{
        transition: all .75s ease-in-out;
        background: transparent;
        height: 100vh;
        width: 60vw;
        position: absolute;
        top:4rem;
        left: -60vw;
        &-links{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding-top: 1rem;

          &-btn{
            text-align: left;
            width: 100%;
            border: none;
            background-color: transparent;
            padding: .75rem;

            a{
                padding: 1rem;
                text-decoration: none;
                font-family: uniSans;
                font-size: 1.25rem;
                color: white;
            }
          }
        }

        &.open{
          background: $color-prime-normal;
          transform: translate(60vw, 0vw);
        }
      }

      &-callToActionBtn{
        height: 4rem;
        &-btn{
          max-width: 15rem;
          height: 100%;
          border: none;
          background-color: $color-prime-callToAction;
          
          a{
            text-decoration: none;
            font-size: 1.6rem;
            font-family: uniSans;
            color: white;
            padding: .75rem;
          }
        }
      }
      &-socialMenu{
        height: 4rem;
        transition: all .5s ease-in-out;
        background: transparent;
        width: 100%;
        position: absolute;
        right: calc(-100vw - 4.5rem);
        z-index: 9;
        
        &-btn{
          margin: .25rem;
          min-height: 3.5rem;
          min-width: 3.5rem;
          z-index: 10;
          svg{
            fill: white ;
          }
          
          }

          &-links{
            height: 4rem;
            svg{
              fill: white;
            }
            &-btn{
              text-align: left;
              border: none;
              background-color: transparent;
              padding: .75rem;
              a{
                  padding: 1rem;
                  text-decoration: none;
                  font-size: 2.5rem;
                  color: white;
              }
            }
          }

          &.open{
            background: $color-prime-normal;
            transform: translate(-100vw, 0vw);
          }
      }
      }
    }
    .activePage{
      a{
          -webkit-filter: drop-shadow(0.15rem 0.15rem $color-prime-dark);
          filter: drop-shadow(0.15rem 0.15rem $color-prime-dark);
          border-bottom: solid white 2px;
      }
  
      &-Mitmachen{
          a{
              color: $color-prime-callToAction;
          }
      }
    }


@media (max-width: $media-breakpoint) {
  .header{
    &-mobile{
      display: flex;
    }
    &-desktop{
      display: none;
    }
  }

  .activePage{
    a{
        -webkit-filter: drop-shadow(0.2rem 0.2rem $color-prime-dark);
        filter: drop-shadow(0.2rem 0.2rem $color-prime-dark);
        border: none;
        text-decoration: underline;
        text-underline-offset: 3px;
    }

    &-Mitmachen{
        a{
            color: white;
            -webkit-filter: drop-shadow(0.2rem 0.2rem $color-prime-dark);
            filter: drop-shadow(0.2rem 0.2rem $color-prime-dark);
        }
    }
  }

}

@media (max-width: 460px) {
  .header{
    &-mobile{
      &-socialMenu{
        transition: all 1s ease-in-out;
        right: calc( -100vw + 4.5rem);
        height: 4rem;
        top: -100vh;
        
        &-links{
          z-index: 1;
          padding-top: 5rem;
          
          display: flex;
          flex-direction: column;
          width: 4.5rem;
          height: auto;
          background-color: $color-prime-normal;

          &-btn{
            padding: 0.25rem;
          }
          a{
            width: 100%;
            svg{
              width: 2.5rem;
            }
          }
        }

        &.open{
          background: $color-prime-light;
          transform: translate(0vw, 100vh);
        }
      }
    }
  }
}