.Footer{
    margin-top: 10rem;
    width: 100%;
    img{
        width: 100%;
    }

    &-Wrapper{
        display: flex;
        flex-direction: row;
        width: 80%;
        
    }

    &-Partner{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $color-prime-light;
        
        margin-top: -1rem;
        padding-bottom: 3rem;
        &-content{
            padding-top: 2rem;
            color: white;
            text-align: center;

            h3{
                font-size: 3rem;
                font-family: 'NexaBold';
                text-transform: uppercase;
                font-weight: bold;
            }
            p{
                font-size: 1.5rem;
                
            }
            margin-bottom: 2rem;
        }

        &-logos{
            margin: 1.5rem 0;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-evenly;
            .img-dropshadow{
                max-width: 15vw;
                min-height: 15vw;
                margin: 5rem 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: auto;
                
                img{
                    
                    margin: auto;
                    max-width: fit-content;
                    height: fit-content;
                    vertical-align: middle;
                }
            }
        }
    }
    &-bottom{
        display: flex;
        justify-content: space-between;
        background-color: $color-prime-dark;
        padding: .25rem;

        a{
            text-decoration: none;
            text-transform: uppercase;
            font-size: 2rem;
            font-family: 'NexaBold';
            color: white;
            font-weight: bold;
            padding: .5rem .75rem;
        }
        
    }
}

@media (max-width: $media-breakpoint) {
    .Footer{

        &-bottom{
            padding: .25rem;
    
            a{
               
                font-size: 1rem;
                padding: .25rem .5rem;
            }
            
        }
        &-Partner-logos{
            flex-direction: column;
            div{
                min-height: 5rem;
                img{
                    padding: 1rem;
                    width: 40vw;
                    margin: 1rem;
                }
            }
        }
    }
  }