.polaroid {
    &-onlymobile{
        display: none;
    }
    &-wrapper{
        background-color: white;
    }
    background-color: white;
    width: 25rem;
    padding: 1rem;
    box-shadow: 10px 20px 15px $color-prime-verydark;
    transform: rotate(350deg) scale(.8);
    &-slider-img {
        width: 100%;
    }

    &-text{
        width: 100%;
        display: flex;
        justify-content: space-around;

        h1{
            margin: .75rem;
            margin-top: -0.5rem;
            font-family: Rawless;
            font-weight: bolder;
            font-size: 2.5rem;
        }
    }
    
}


@media (max-width: $media-breakpoint) {
.polaroid {
    transform: rotate(0deg);

    &-onlymobile{
        display:  block;
        position: relative;
        top: .75rem;
        left: .5rem;
        margin: 0rem;
        padding: 0;
        z-index: 5;
        width: 0;
        height: 0;
        svg{
            width: 4rem;
        }
        
    }

    &-text{

        h1{
            margin-top: 2rem;
        }
    }

}
}

@media (max-width: 460px) {
    .polaroid {
       
    padding: 1rem;


    &-text{

        h1{
            margin-top: 1rem;
            font-size: 1.5rem;
        }
    }
    }
    }

    @media (max-width: 345px) {
        .polaroid {
           
        &-text{
    
            h1{
                font-size: 1rem;
            }
        }
        }
        }