.burgerMenu-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    transition: all .5s ease-in-out;
    
    /* border: 3px solid #fff; */
  }
  .burgerMenu-btn__burger {
    width: 3rem;
    height: .4rem;
    background: #fff;
    border-radius: .25rem;
    transition: all .5s ease-in-out;
  }
  .burgerMenu-btn__burger::before,
  .burgerMenu-btn__burger::after {
    content: '';
    position: absolute;
    width: 3rem;
    height: .4rem;
    background: #fff;
    border-radius: .25rem;
    transition: all .5s ease-in-out;
  }
  .burgerMenu-btn__burger::before {
    transform: translateY(-16px);
  }
  .burgerMenu-btn__burger::after {
    transform: translateY(16px);
  }
  /* ANIMATION */
  .burgerMenu-btn.open .burgerMenu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }
  .burgerMenu-btn.open .burgerMenu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }
  .burgerMenu-btn.open .burgerMenu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }