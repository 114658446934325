.img {

    &-left {
        width: 80%;
        margin-left: 3%;
        border-radius: 1.5rem;
        // box-shadow: 0 3px 10px rgb(0 0 0 / 1);
        box-shadow: -2.5rem 2.5rem $color-prime-normal;
        transform: rotate(3deg);
    }

    &-right {
        width: 80%;
        margin-left: 17%;
        border-radius: 1.5rem;
        box-shadow: 2.5rem 2.5rem $color-prime-normal;
        transform: rotate(-3deg);
    }

    &-dropshadow {
        max-width: 20rem;
        -webkit-filter: drop-shadow(0.15rem 0.15rem $color-prime-normal);
        filter: drop-shadow(0.15rem 0.15rem $color-prime-normal);
        display: flex;
        flex-direction: column;
    }

    &-invert {
        -webkit-filter: invert(1) drop-shadow(0.15rem 0.15rem $color-prime-light);
        filter: invert(1) drop-shadow(0.15rem 0.15rem $color-prime-light);
        display: flex;
        flex-direction: column;
    }
    // &-selected {
    //     -webkit-filter: invert(1) drop-shadow(0.15rem 0.15rem $color-prime-light);
    //     filter: invert(1) drop-shadow(0.15rem 0.15rem $color-prime-light);
    //     display: flex;
    //     flex-direction: column;
    // }
    &-selected {
        -webkit-filter: invert(1) drop-shadow(0.15rem 0.15rem $color-prime-red);
        filter: invert(1) drop-shadow(0.15rem 0.15rem $color-prime-red);
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: $media-breakpoint) {
    .img {
        &-left {
            width: 80%;
            margin: auto;
            border-radius: 1.5rem;
            box-shadow: 0 3px 10px rgb(0 0 0 / 1);
            transform: rotate(0deg);
        }

        &-right {
            width: 80%;
            margin: auto;
            border-radius: 1.5rem;
            box-shadow: 0 3px 10px rgb(0 0 0 / 1);
            transform: rotate(0deg);
        }

        &-dropshadow {
            max-width: 10rem;

            img {
                max-width: 100%;
            }
        }
    }
}