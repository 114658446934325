.spiele{

    background-color: $color-prime-dark;
    padding-top: 5rem;
    padding-bottom: 2rem;

    &-hr{
        display: flex;
        justify-content: center;
        width: 100%;
        hr{
            color: white;
            width: 45%;
            margin-top: .5rem;
        }
    }

    &-content{
        padding: 1rem;
        color: white;
        font-family: NexaLight;

        b{
            font-family: NexaBold;
            font-size: 1.5rem;
            padding-right: .25rem;
        }

        h2{
            padding: 0 1rem;
        }
    }

    &-info{
        
        display: flex;
        justify-content: flex-start;
        width: 100%;
        &-stats{
            padding: 0 1rem;
        }
        &-abteilungsleitung{
            padding: 0 1rem;
        }
    }

    &-beschreibung{
        font-family: NexaLight;
        font-size: 1.25rem;
        padding: 1rem;
        max-width: 90%;
        margin: auto;
        text-align: center;
    }

    &-teams{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &-abteilungen{
        display: flex;
        justify-content: center;
        .games-imgs{
            display: flex;
            justify-content: center;
            max-width: 90%;

            .game-img{
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 3.5rem;
                max-height: 3.5rem;
                margin: 1rem;

                &:hover{
                    transform: scale(1.25);
                }
            }



            img{
                max-width: 100%;
                max-height: 3.5rem;
            }
        }
    }
}


@media (max-width: $media-breakpoint) {
    .games-imgs{
        flex-wrap: wrap;
        max-width: 100%;
        width: 100%;
    }
    .spiele{
        &-hr{
            hr{
                margin-top: .5rem;
                width: 75%;
            }
        }
    }
}