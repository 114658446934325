.home {
    &-game-logos {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;

        a{
            display: flex;
            flex-direction: column;
            justify-content: center;

        }
        .img-dropshadow {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 1rem;
        }

        img {
            max-width: 22rem;
            max-height: 15rem;
        }
    }
}

.content-element {
    display: flex;
    justify-content: space-around;

    &-left {
        width: 100%;

    }

    &-right {
        width: 100%;

    }

    &-first {
        padding-top: 45vh;
        display: flex;
        flex-direction: row;
        background-image: linear-gradient(to bottom, rgba(26, 38, 71, 0), rgba(26, 38, 71, 1) Calc(10vw));
    }

    &-rest {
        padding-top: 10vh;
        display: flex;
        flex-direction: row;
        background-color: $color-prime-dark;


        &-games {

            margin: 3rem;
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            span {
                display: flex;
                justify-content: space-around;
                width: 100%;

                h2 {
                    text-align: center;
                    margin: 3rem auto;
                    font-weight: bold;
                    font-size: 4rem;
                    font-family: 'NexaBold';
                    text-transform: uppercase;
                    -webkit-filter: drop-shadow(0.25rem 0.25rem $color-prime-light);
                    filter: drop-shadow(0.25rem 0.25rem $color-prime-light);
                }
            }

        }

    }

    h2 {
        font-family: 'NexaBold';
        color: white;
        text-align: left;
        margin-left: 1rem;
        margin-top: 10%;
        font-size: 2.75rem;
        margin-bottom: .5rem;
    }

    p {
        margin: auto;
        font-family: NexaLight;
        color: white;
        text-align: justify;
        margin-right: 3rem;
        margin-left: 1rem;
        margin-top: 0;
        font-size: 1.25rem;
    }
}

.home-text{
    font-size: 1.75rem !important;
}

.home-title {
    position: relative;
    top: 60%;
    left: 20%;
    color: white;
    font-size: Calc(Calc(100vw / 100) * 5);

    &-text {
        &-special {
            font-family: NexaLight;
            display: block;
        }

        display: block;
        font-family: NexaBold;

        -webkit-filter: drop-shadow(0.2rem 0.2rem $color-prime-light);
        filter: drop-shadow(0.2rem 0.2rem $color-prime-light);
    }
}

.element {
    &-polaroid {
        position: relative;
        top: 12rem;
        right: -8rem;
        width: 0;

        scale: 1.25;
    }
}


@media (max-width: 1400px) {
    .element-polaroid {
        scale: 1;
    }
}

@media (max-width: $media-breakpoint) {

    .home-text{
        font-size: 1.25rem !important;
    }

    .element-polaroid {

        display: flex;
        justify-content: space-around;
        margin: 3rem;
        right: auto;
        width: auto;
        position: static;
        font-size: 0.5rem;

    }

    .content-element {
        flex-direction: column;
        justify-content: center;


        &-right {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            padding: 0;
            margin: 0;
            width: 100%;
        }

        &-left {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            padding: 0;
            margin: 0;
        }

        &-first {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            padding: 0;
            margin: 0;
        }

        &-rest {

            &-games {

                margin: 1rem;
                max-width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                span {

                    h2 {
                        margin: 2rem auto;
                        font-size: 2.5rem;

                    }
                }

            }
        }

        h2 {
            margin: 1rem;
        }

        p {
            margin: 1rem;
        }

    }

    .home {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &-title {
            position: static;
            margin-top: 6rem;
            font-size: Calc(Calc(100vw / 50) + 3rem);

            &-text {
                &-special {
                    width: 100%;
                    text-align: center;
                }

                width: 100%;
                text-align: center;
            }
        }
    }

}


.mediakit{
    margin: 0 2rem;
    padding: auto;
    background-color: $color-prime-callToAction;
    border: none;
    border-radius: .5rem;
    min-height: 4rem;
    box-shadow: 4px 2px 4px $color-prime-verydark;
    a{
        padding: 2.5rem;
        font-size: 2.5rem;
        text-decoration: none;
        color: white;
        font-weight: bold;
    }

    &:hover{
        background-color: $color-prime-normal;
    }
}

@media (max-width: 460px) {
    .home-title {
        font-size: Calc(Calc(100vw / 50) + 2rem);
    }
}

@media (max-width: 330px) {
    .home-title {
        font-size: 2rem;
    }


}

.uberuns{
    padding: 1rem 0 6rem 0;
}