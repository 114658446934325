.TeamCard {
    margin: 1rem;
    // border: solid 3px white;
    // border-radius: 1rem;
    // padding: .5rem;
    &-name {
        font-size: 1.75rem;
        padding: 0.25rem 0rem;
        text-align: center;
        color: white;
        -webkit-filter: drop-shadow(0.06rem 0.06rem $color-prime-light);
        filter: drop-shadow(0.06rem 0.06rem $color-prime-light);
    }

    &-Liga{
        font-size: 1.5rem;
        text-align: center;
        font-family: NexaBold;
    }

    &-Spieler{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-wrap: wrap;
        font-size: 1.25rem;
        min-width: 10rem;
        span{
            font-family: NexaLight;
            background-color: $color-prime-light;
            text-align: center;
            padding: .25rem;
            margin: .25rem;
            border-radius: .5rem;
            box-shadow: 0 3px 10px rgb(0 0 0 / 1);
        }
    }
}