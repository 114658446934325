//fonts

//LOGO
@font-face {
    font-family: 'Cocogoose';
    src: url('../assets/fonts/Cocogoose.ttf') format('truetype');
}

//text
@font-face {
    font-family: 'NexaBold';
    src: url('../assets/fonts/NexaBold.otf') format('opentype');
}

@font-face {
    font-family: 'NexaLight';
    src: url('../assets/fonts/NexaLight.otf') format('opentype');
}

//header
@font-face {
    font-family: 'uniSans';
    src: url('../assets/fonts/uniSans.otf') format('opentype');
}

//polaroid
@font-face {
    font-family: 'Rawless';
    src: url('../assets/fonts/Rawless.ttf') format('truetype');
    //src: url('../assets/fonts/Autography.otf') format('opentype');
}

//general
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

//imports:
@import 'variables';
@import 'Header';
@import 'burgerMenuAnim';
@import 'slideshow';
@import 'polaroid';
@import 'home';
@import 'ImgLeftRight';
@import 'Spiele';
@import 'DepartmentInfoCard';
@import 'TeamCard';
@import 'Mitmachen';
@import 'Impressum';
@import 'Footer';




body {
    background-color: $color-prime-dark;

    //background-attachment:fixed;
}


.onlymobile {
    display: none;
    img{
        display: none;
    }
}


.ContentWrapper{
    min-height: calc(100vh - 4rem);
}

@media (min-width: $media-breakpoint) {
    body {
        background-image: url('../assets/img/bg4.png');
        background-size: contain;
        background-repeat: no-repeat;
    }


}


@media (max-width: $media-breakpoint) {


    .onlymobile {
        display: flex;

        img {
            display: flex;
        }
    }

    .onlydesktop {
        display: none;

        img {
            display: none;
        }
    }
}
