.Mitmachen{
    display: flex;
    padding: 10rem 0 15rem 0;
    background-color: $color-prime-dark;

    &-img{
        position: absolute;
        z-index: 1;
        width: 45%;
        left: 52.5%;
        top: 4rem
    }
    &-Left{
        width: 100%;
        z-index: 2;
    }
    &-Right{
        width: 100%;
        padding: 6rem 0;
        z-index: 2;
    }

    &-text{
        &-titel{
            font-family: uniSans;
            font-size: 3rem;
            color: white;
            text-align: center;
            -webkit-filter: drop-shadow(0.2rem 0.2rem $color-prime-light);
            filter: drop-shadow(0.2rem 0.2rem $color-prime-light);
        }
        &-content{
            font-family: NexaLight;
            font-size: 1.75rem;   
            color: white;     
            padding: 2rem 5rem 0 5rem;
    }
        &-btn{
            display: flex;
            margin: 1rem;
            justify-content: center;
            height: fit-content;
                svg{
                    height: fit-content;
                    width: 100%;
                    fill:white;
                    width: 2.5rem;
                    margin: 0 1rem;
                }
            
        }
    }
}

@media (max-width: $media-breakpoint) {
    .Mitmachen{
        padding: 6rem 0;
        flex-direction: column-reverse;
        &-img{
            display: none;
        }

        &-Left {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            padding: 0;
            margin: 0;
            margin-top: 1rem;
            width: 100%;
        }
        &-Right{
            width: 100%;
            padding: 0;
            z-index: 2;
        }

        &-text{
            &-content{
                font-family: NexaLight;
                font-size: 1.25rem;   
                color: white;     
                padding: 1rem 1.75rem 0 1.75rem;
        }
        }
    }
}