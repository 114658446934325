$media-breakpoint: 1212px;

$color-prime-light: #2953c9;
$color-prime-callToAction: #20419E;
$color-prime-normal: #193992;
$color-prime-dark: #1a2647;
$color-prime-verydark: #0b1329;
$color-prime-red: #e2001a;


